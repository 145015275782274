import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";
import "./homescreen.scss";
import { MdClose } from "react-icons/md";
import MobileKeypad from "./keypad";
import ToolTip from "./tooltip";
import { connect } from "react-redux";
import { getClientKey } from "../actions/stripe/getClientKeyAction";

function Homescreen({ getClientKey, clientkey }) {
  const navigate = useNavigate();
  const location = useLocation();

  const [takeawayName, setTakeawayName] = useState();
  const [isValidAmount, setIsValidAmount] = useState(true);
  const [notesInputFocused, setNotesInputFocused] = useState(
    localStorage.getItem("notes") || ""
  );
  const [amount, setAmount] = useState(
    parseFloat(localStorage.getItem("amount"))
  );
  const takeaway_name = localStorage.getItem("takeaway_name") || "";
  
  const [currency, setCurrency] = useState({
    'code': 'GBP',
    'symbol': '£'
  });

  const currency_info = {
    'code': localStorage.getItem("currency_code") || "GBP",
    'symbol': localStorage.getItem("currency_symbol") || "£"
  };

  const handleChildButtonClick = (buttonValue) => {
    setAmount(buttonValue);
    setIsValidAmount(true);
  };

  const handleNotes = async (event) => {
    const notes = event?.target?.value;
    console.log(notes);
    setNotesInputFocused(notes);
    localStorage.setItem("notes", notes);
  };

  function capitalizeFirstLetter(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const clientId = queryParams.get("client_id");
    if (!clientId) {
      alert("Please scan code");
    }
    const post = {
      shopId: clientId,
      action: "getClientApiKey",
    };
    getClientKey(post);
  }, []);

  useEffect(() => {
    if (takeaway_name !== "" && takeaway_name !== "undefined") {
      setTakeawayName(
        capitalizeFirstLetter(localStorage.getItem("takeaway_name"))
      );
    }
  }, [takeaway_name]);

  useEffect(() => {
    setCurrency({
      'code': localStorage.getItem("currency_code") || "GBP",
      'symbol': localStorage.getItem("currency_symbol") || "£"
    });
  }, [currency_info]);

  function clickContinue() {
    const checkAmount = localStorage.getItem("amount");
    if (checkAmount && parseFloat(checkAmount) >= 0.5) {
      setIsValidAmount(true);
      const queryParams = new URLSearchParams(location.search);
      navigate("/payment?client_id=" + queryParams.get("client_id"));
    } else {
      setIsValidAmount(false);
    }
  }
  return (
    <section>
      <div className="main-view">
        <Container>
          <Row className="justify-content-center">
            <Col md={6} className="pad-imp">
              <div className="payment-screen">
                <div className="header">
                  <div className="colse-option">
                    <div className="close">
                      <MdClose />
                    </div>
                    <div className="title">{takeawayName}</div>
                    <div className="options">
                      <ToolTip />
                    </div>
                  </div>
                </div>
                <div className="details-amount-notes">
                  <div className="cust-details">
                    <div className="title">ENTER BILL AMOUNT</div>
                    <div className="amount-container">
                      <div className={`amount ${amount > 0 ? "active" : ""}`}>
                        { currency.symbol } {amount ? amount : "0"}
                        <hr className="amount-line" />
                      </div>
                    </div>

                    <div className="cust-name">Paying {takeawayName}</div>
                    <div className="input-notes">
                      <input
                        type="text"
                        placeholder="Add Notes"
                        onChange={handleNotes}
                        value={notesInputFocused}
                      />
                    </div>
                  </div>
                </div>

                {!isValidAmount && (
                  <div
                    style={{
                      color: "red",
                      marginBottom: "10px",
                      textAlign: "center",
                    }}
                  >
                    Please enter an amount above £0.50
                  </div>
                )}
                <div className="proceed-btn">
                  {amount ? (
                    <button className="proceed-icon" onClick={clickContinue}>
                      <div className="cnt-btn">Continue</div>
                    </button>
                  ) : null}
                </div>
                <div className="keypad">
                  <MobileKeypad onChildButtonClick={handleChildButtonClick} />
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </section>
  );
}

const mapStateToProps = (state) => ({
  clientkey: state.clientkey,
});

const mapDispatchToProps = {
  getClientKey,
};

export default connect(mapStateToProps, mapDispatchToProps)(Homescreen);
