import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import fail from "./img/failed.json";
import Lottie from "react-lottie-player";
import "./failed.scss";
import { Link } from "react-router-dom";

function capitalizeFirstLetter(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}
export default function Failed() {
  const [takeawayName, setTakeawayName] = useState();
  const [amountValue, setAmountValue] = useState();
  const [currentDateTime, setCurrentDateTime] = useState('');
  const [currency, setCurrency] = useState({
    'code': 'GBP',
    'symbol': '£'
  });

  const currency_info = {
    'code': localStorage.getItem("currency_code") || "GBP",
    'symbol': localStorage.getItem("currency_symbol") || "£"
  };

  useEffect(() => {
    setCurrency({
      'code': localStorage.getItem("currency_code") || "GBP",
      'symbol': localStorage.getItem("currency_symbol") || "£"
    });
  }, []);

  useEffect(() => {
    if(takeawayName!==''){
      setTakeawayName(capitalizeFirstLetter(localStorage.getItem('takeaway_name')));
      setAmountValue(localStorage.getItem('amount'));
    }
  }, [takeawayName]);

  useEffect(() => {
    const updateDateTime = () => {
      const options = {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
      };

      const dateTimeString = new Date().toLocaleString('en-US', options);
      setCurrentDateTime(dateTimeString);
    };
    updateDateTime();
    const intervalId = setInterval(updateDateTime, 1000);
    return () => clearInterval(intervalId);
  }, []);
  return (
    <section>
      <div className="failed-details">
        <Container>
          <Row className="justify-content-center">
            <Col md={6}>
              <div className="success-screen">
                <div className="gif">
                  <Lottie
                    loop
                    animationData={fail}
                    play
                    style={{ height: "300px", width: "150px" }}
                  />

                  <div className="paid-info">
                    <div className="amount">{currency.symbol} {amountValue}</div>
                    <div className="cust-name">Payment Failed to</div>
                    <div className="date">{takeawayName}</div>
                  </div>
                </div>
                <div className="paid-info">
                  <div className="cust-date">{currentDateTime}</div>
                </div>
                <Link to="/">
                  <div className="try">
                    <button>Retry</button>
                  </div>
                </Link>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </section>
  );
}
