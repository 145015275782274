import "./main.scss";
import { BrowserRouter as Router } from "react-router-dom";
import Routing from "./router/router";

import "bootstrap/dist/css/bootstrap.min.css";

function Main() {
  return (
    <>
      <Router>
        <Routing />
      </Router>
    </>
  );
}

export default Main;
