// src/reducers/checkout/stripePaymentReducer.js
import { PAYMENT_INTENT_SUCCESS, PAYMENT_INTENT_FAILURE } from "../../actions/types";

const initialState = {
  stripepayment: null,
  error: null,
};

export const stripePaymentReducer = (state = initialState, action) => {
  switch (action.type) {
    case PAYMENT_INTENT_SUCCESS:
        // console.log(action.payload);
        // localStorage.setItem('client_secret',action.payload.data.client_secret);
      return {
        ...state,
        stripepayment: action.payload,
        error: null,
      };
    case PAYMENT_INTENT_FAILURE:
      return {
        ...state,
        stripepayment: null,
        error: action.payload,
      };
    default:
      return state;
  }
};
