import React, { useState, useEffect } from "react";
import Table from "react-bootstrap/Table";
import { IoIosArrowBack } from "react-icons/io";
import "./homescreen.scss";

function MobileKeypad({ onChildButtonClick  }) {
  const [selectedButtons, setSelectedButtons] = useState([]);

  useEffect(() => {
    const amount = selectedButtons.join('');
    if(amount!==''){
      localStorage.setItem('amount',amount);
      onChildButtonClick(amount);
    }
  }, [selectedButtons]);

  const handleRemoveLast = () => {
    setSelectedButtons((prevButtons) => {
      if (prevButtons && prevButtons.length > 0) {
        // alert(prevButtons.slice(0, -1));
        return prevButtons.slice(0, -1);
      } else {
        onChildButtonClick(0);
        localStorage.setItem('amount',0);
        return [];
      }
    });
  };

  const handleButtonClick = (buttonValue) => {
    if (buttonValue === "." && selectedButtons.includes(".")) {
      return;
    }
    if ((buttonValue === "." && selectedButtons.length === 0)) {
      setSelectedButtons(["0", "."]);
    } else {
      setSelectedButtons([...selectedButtons, buttonValue]);
    }
  };

  return (
    <Table className="Mobile-key-pad">
      <thead></thead>
      <tbody className="number-modify">
        <tr>
          <td>
            <div className={`center ${selectedButtons.includes("1") ? "amount" : ""}`}>
              <button onClick={() => handleButtonClick("1")}>1</button>
            </div>
          </td>
          <td>
            <div className={`center ${selectedButtons.includes("2") ? "amount" : ""}`}>
              <button onClick={() => handleButtonClick("2")}>2</button>
            </div>
          </td>
          <td>
            <div className={`center ${selectedButtons.includes("3") ? "amount" : ""}`}>
              <button onClick={() => handleButtonClick("3")}>3</button>
            </div>
          </td>
        </tr>
        <tr>
          <td>
            <div className={`center ${selectedButtons.includes("4") ? "amount" : ""}`}>
              <button onClick={() => handleButtonClick("4")}>4</button>
            </div>
          </td>
          <td>
            <div className={`center ${selectedButtons.includes("5") ? "amount" : ""}`}>
              <button onClick={() => handleButtonClick("5")}>5</button>
            </div>
          </td>
          <td>
            <div className={`center ${selectedButtons.includes("6") ? "amount" : ""}`}>
              <button onClick={() => handleButtonClick("6")}>6</button>
            </div>
          </td>
        </tr>
        <tr>
          <td>
            <div className={`center ${selectedButtons.includes("7") ? "amount" : ""}`}>
              <button onClick={() => handleButtonClick("7")}>7</button>
            </div>
          </td>
          <td>
            <div className={`center ${selectedButtons.includes("8") ? "amount" : ""}`}>
              <button onClick={() => handleButtonClick("8")}>8</button>
            </div>
          </td>
          <td>
            <div className={`center ${selectedButtons.includes("9") ? "amount" : ""}`}>
              <button onClick={() => handleButtonClick("9")}>9</button>
            </div>
          </td>
        </tr>
        <tr>
          <td>
            <div className="center">
              <button onClick={() => handleButtonClick(".")}>.</button>
            </div>
          </td>
          <td>
            <div className="center">
              <button onClick={() => handleButtonClick("0")}>0</button>
            </div>
          </td>
          <td>
            <div className="center">
              <button onClick={() => handleRemoveLast("back")}>
                <IoIosArrowBack />
              </button>
            </div>
          </td>
        </tr>
      </tbody>
    </Table>
  );
}

export default MobileKeypad;
