import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import ok from "./img/success.json";
import Lottie from "react-lottie-player";
import "./success.scss";
import { BsShareFill } from "react-icons/bs";
import { Link } from "react-router-dom";
import html2canvas from "html2canvas";

function capitalizeFirstLetter(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}
export default function Success() {
  const [takeawayName, setTakeawayName] = useState();
  const [amountValue, setAmountValue] = useState();
  const [currentDateTime, setCurrentDateTime] = useState("");
  const [currency, setCurrency] = useState({
    'code': 'GBP',
    'symbol': '£'
  });

  const currency_info = {
    'code': localStorage.getItem("currency_code") || "GBP",
    'symbol': localStorage.getItem("currency_symbol") || "£"
  };

  useEffect(() => {
    setCurrency({
      'code': localStorage.getItem("currency_code") || "GBP",
      'symbol': localStorage.getItem("currency_symbol") || "£"
    });
  }, []);

  useEffect(() => {
    if (takeawayName !== "") {
      setTakeawayName(
        capitalizeFirstLetter(localStorage.getItem("takeaway_name"))
      );
      setAmountValue(localStorage.getItem("amount"));
      // localStorage.removeItem("amount");
    }
  }, [takeawayName]);

  useEffect(() => {
    const updateDateTime = () => {
      const options = {
        year: "numeric",
        month: "long",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      };

      const dateTimeString = new Date().toLocaleString("en-US", options);
      setCurrentDateTime(dateTimeString);
    };
    updateDateTime();
    const intervalId = setInterval(updateDateTime, 1000);
    return () => clearInterval(intervalId);
  }, []);

  const handleShareClick = async () => {
    const successScreen = document.getElementById("success-screen");

    if (successScreen && navigator.share) {
      try {
        const canvas = await html2canvas(successScreen, {
          scrollY: -window.scrollY,
          allowTaint: true,
          useCORS: true,
        });

        const imageData = canvas.toDataURL("image/png");

        await navigator.share({
          text:
            "Check out my payment success:\n" +
            "Amount: " + currency.symbol +
            amountValue +
            "\n" +
            "Paid to: " +
            takeawayName +
            "\n" +
            "Date: " +
            currentDateTime +
            "\n" +
            "UPI Transaction ID: 331422140787",
          files: [
            new File([dataURItoBlob(imageData)], "payment_success.png", {
              type: "image/png",
            }),
          ],
        });
      } catch (error) {
        console.error("Error capturing screenshot:", error);
      }
    }
  };

  function dataURItoBlob(dataURI) {
    const byteString = atob(dataURI.split(",")[1]);
    const mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: mimeString });
  }

  return (
    <section>
      <div id="success-screen" className="success-details">
        <Container>
          <Row className="justify-content-center">
            <Col md={6}>
              <div className="success-screen">
                <div className="gif">
                  <Lottie
                    animationData={ok}
                    play
                    style={{ height: "300px", width: "300px" }}
                  />

                  <div className="paid-info">
                    <div className="amount">{currency.symbol} {amountValue}</div>
                    <div className="cust-name">Paid to {takeawayName}</div>
                  </div>
                </div>
                <div className="paid-info">
                  <div className="cust-date">{currentDateTime}</div>
                </div>

                <div className="share-done">
                  <div className="share">
                    <button onClick={handleShareClick}>
                      Share Now <BsShareFill />
                    </button>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </section>
  );
}
