import { createContext, useContext, useState, useEffect } from 'react';

const AmountContext = createContext();

export const AmountProvider = ({ children }) => {
  const [amount, setAmount] = useState(localStorage.getItem('amount') || '');

  useEffect(() => {
    const handleStorageChange = () => {
      setAmount(localStorage.getItem('amount') || '');
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  return (
    <AmountContext.Provider value={{ amount, setAmount }}>
      {children}
    </AmountContext.Provider>
  );
};

export const useAmount = () => {
  const context = useContext(AmountContext);
  if (!context) {
    throw new Error('useAmount must be used within an AmountProvider');
  }
  return context;
};
