// src/reducers/index.js
import { combineReducers } from "redux";

import { stripePaymentReducer } from "./stripe/paymentIntentId";
import { stripeReducer } from "./stripe/stripeReducer";
import { clientKeyReducer } from "./stripe/clientKeyReducer";

const rootReducer = combineReducers({
  stripe: stripeReducer,
  stripepayment: stripePaymentReducer,
  clientkey:clientKeyReducer
});

export default rootReducer;
